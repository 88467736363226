import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Rating, Link } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import commentsData from '../Assets/comments.json'; // Assurez-vous d'avoir un fichier JSON avec les commentaires
import './CommentCarousel.css';
const CommentCarousel = ({ productId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const productComments = commentsData.filter(comment => comment.productId === 1);
  const { t } = useTranslation();  // Ajout de la fonction de traduction

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % productComments.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [productComments.length]);

  return (
    <Box  id="customer-reviews" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div className="CommentCarousel-display">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          style={{ width: '100%', maxWidth: '600px' }}
        >
          {productComments.length > 0 && (
            <Card elevation={3} sx={{ p: 2, textAlign: 'center', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="body1" component={Link} href={productComments[currentIndex].trustpilotUrl} target="_blank" sx={{ textDecoration: 'none', color: 'secondary.main', fontWeight: 'bold', display: 'block' }}>
                  {productComments[currentIndex].firstName} {productComments[currentIndex].lastName}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  "{productComments[currentIndex].title}"
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(productComments[currentIndex].date).toLocaleDateString('fr-FR')}
                </Typography>
                <Rating value={productComments[currentIndex].rating} readOnly precision={0.5} sx={{ my: 1 }} />
                <Typography variant="body1" sx={{ fontWeight: '' }}>
                  "{productComments[currentIndex].review}"
                </Typography>
              </CardContent>
            </Card>
          )}
        </motion.div>
      </AnimatePresence>
      </div>
    </Box>
  );
};

export default CommentCarousel;